import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/Seo'
import NavbarGlobal from '../components/NavbarGlobal'
import FooterGlobal from '../components/FooterGlobal'
import { FaCheckCircle } from "react-icons/fa"


export default function TreningPersonalny() {
  
  return (

    <div className='layout'>

      <Seo 
        title="Trening Personalny"
        description="Trening osobisty dla szukających zmiany, którzy chcą poprawić wygląd sylwetki i w zdrowy sposób zadbać o formę pod opieką specjalisty."
        keywords="trening personalny, fizjoterapia"
        url="https://www.fizjopersonalny.pl/trening-personalny"
      />

      <div className='header_gradient'>
        <NavbarGlobal />
        <div className='offer_box'>

          <div className='offer_intro_header'>
            <h1>Trening personalny</h1>
            <span>Lepszy wygląd i wyższy poziom sprawności</span>
            <p>Trening osobisty dla szukających zmiany, którzy chcą poprawić wygląd sylwetki i w zdrowy sposób zadbać o formę pod opieką specjalisty.</p>
            <ul>
              <li><FaCheckCircle />kameralna przestrzeń</li>
              <li><FaCheckCircle />indywidualny plan treningowy</li>
              <li><FaCheckCircle />wsparcie fizjoterapeutyczne</li>
            </ul>
            <div className='offer_intro_buttons'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
              <Link to="#wiecej" className='button offer_intro_button'>dowiedz się więcej</Link>
            </div>
          </div>

          <div className='offer_intro_image'>
            <StaticImage src="../images/offer/trening-personalny.jpg" alt="Trening personalny | Warszawa"/>
          </div>

        </div>
      </div>
      
      <div className='content'>
      
        <section id="wiecej">
          <div className='offer_content'>
            <h2>Trening personalny zwiększa odporność na wymówki</h2>
            <span>Zaskocz ciało i umysł zmianą na lepsze</span>
            <p>Jędrne uda, kształtne pośladki, duży biceps, silna klatka czy brak zadyszki przy wchodzeniu po schodach to realne cele. Trudno uwierzyć w ich osiągnięcie, gdy wygląd sylwetki nie zadowala, zadyszka dusi na każdym piętrze coraz mocniej, a motywacja sięga najniższego poziomu.</p>

            <div className='offer_client'>
              <ul>
                <li>
                  <h3>niezadowalająca sylwetka</h3>
                  <StaticImage src="../images/offer/zgarbiona-sylwetka.jpg" alt="Zgarbiona sylwetka"/>
                </li>
                <li>
                  <h3>zmniejszona wydolność</h3>
                  <StaticImage src="../images/offer/zmniejszona-wydolnosc.jpg" alt="Zmniejszona wydolność"/>
                </li>
                <li>
                  <h3>brak motywacji</h3>
                  <StaticImage src="../images/offer/brak-motywacji.jpg" alt="Brak motywacji"/>
                </li>
              </ul>
            </div>

            <p>Trening personalny wspiera Cię w dążeniu do osiągnięcia zaplanowanych celów. Kształuje Twoje ciało, gdy gubisz nadmiarowe kilogramy lub budujesz silne mięśnie. Hartuje umysł pozwalając poskromić nietrwałą motywację. Pomaga odnaleźć się w świecie ćwiczeń, gdy nie wiesz co robić.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content offer_content_background'>
            <h2>Trening personalny w opinii klientów</h2>
            <span>Rekomendacje osób, które zdecydowały się na współpracę</span>
            <p>Przedstawione poniżej rekomendacje stanowią część opinii, które możesz znaleźć w takich miejscach jak: <a href="https://goo.gl/maps/4H6dS7wtz8Ue3JSh9" target="_blank" rel="noreferrer">Google Maps</a>, <a href="https://www.facebook.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Facebook</a>, <a href="https://www.instagram.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Instagram</a> czy <a href="https://www.znanylekarz.pl/lukasz-kalenczuk/fizjoterapeuta/warszawa" target="_blank" rel="noreferrer">Znany Lekarz</a>.</p>

            <div className='offer_feed'>
              <ul>
                <li>
                  <StaticImage src="../images/feed/gm-2.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/zl-1.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/gm-6.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                  <StaticImage src="../images/feed/gm-17.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
              </ul>
            </div>

            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content'>
            <h2>Trening personalny kształtuje ciało i hartuje umysł</h2>
            <span>Osobiste wsparcie na drodze do osiągania celów</span>
            <p>Rozpoczynamy od dokładnego wywiadu. Spytam m.in. o stan zdrowia, doświadczenie treningowe, itp. Następnie przejdziemy do szczegółowego badania ruchowego. Ocenię m.in. mobilność oraz technikę ćwiczeń. Na tej podstawie zaprogramuje Twój indywidualny plan treningowy.</p>
            
            <div className='offer_fitness_protocol'>
              <ul>
                <li>
                  <h3>indywidualny plan treningowy</h3>
                  <StaticImage src="../images/offer/plan-treningowy.jpg" alt="Indywidualny plan treningowy"/>
                </li>
                <li>
                  <h3>większa wydolność</h3>
                  <StaticImage src="../images/offer/wieksza-wydolnosc.jpg" alt="Zwiększona wydolność"/>
                </li>
                <li>
                  <h3>atrakcyjna sylwetka</h3>
                  <StaticImage src="../images/offer/atrakcyjna-sylwetka.jpg" alt="Atrakcyjna sylwetka"/>
                </li>
              </ul>
            </div>

            <p>Trening personalny realizowany jest w kameralnej przestrzeni Studia bez udziału współćwiczących. Tutaj możesz być w 100% sobą. W spokoju zrzucać zbędne kilogramy, kształtować sylwetkę, podnosić wydolność ciała. Pod moją opieką będziesz popełniać błędy i uczyć się ruchu, który daje satysfakcję.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>

      </div>
      <FooterGlobal />
    </div>

  )
}